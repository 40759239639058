#root {
  /* background-color: rgb(222, 223, 224); */
  height: 100vh;
  margin: 0px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
.container {
  width: 100vw;
  height: 100vh;
  /* display: flex;
  flex-direction: column; */
}

.cont {
  height: 30rem;
  width: 27.5rem;
  position: absolute;
  top: 22%;
  left: 33.7%;
  z-index: 2;
  background-color: white;
  box-shadow: 0.5px 0.5px 5px 1px rgb(181, 184, 192);
  display: flex;
  align-items: center;
}

body {
  margin: 0px;
}

.back {
  width: 100vw;
  height: 100vh;
  background-image: url("./components/logo.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(50rem);
}
.body {
  height: 22rem;
  width: 27.5rem;
  position: absolute;
  top: 22%;
  left: 33.7%;
  z-index: 2;
  background-color: white;
  box-shadow: 0.5px 0.5px 5px 1px rgb(181, 184, 192);
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* padding: 1rem; */
  /* color: steelblue; */
}

.form {
  /* height: 73%; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formss {
  /* height: 73%; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.forms {
  padding: 0rem 3rem 0rem 3rem;
}

.arrow {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  float: left;
}

.arrow:hover {
  background-color: rgb(219, 220, 223);
}

input {
  border: none;
  width: 100%;
  height: 2rem;
  padding: 0px;
  font-size: large;
  font-weight: lighter;
}
input:focus {
  outline: none;
}
.fg {
  font-size: small;
  color: rgb(28, 100, 194);
}

h2 {
  color: rgb(19, 19, 27);
}

.btn {
  border: none;
  background: rgb(31, 98, 185);
  color: white;
  height: 2.1rem;
  width: 6.9rem;
  float: right;
  font-size: medium;
}

.btn:hover {
  background-color: rgb(57, 125, 213);
  cursor: pointer;
}

::placeholder {
  color: rgb(143, 146, 146);
  font-size: medium;
}

.users {
  padding: 1rem;
}

.getbtn {
  border: none;
  background: rgb(17, 112, 190);
  color: white;
  height: 2.5rem;
  width: 8rem;
  font-weight: bold;
}

/* button {
  border-radius: 5px;
} */

.footer {
  z-index: 2;
  font-size: small;
  float: right;
  margin: 0px;
}

footer {
  padding: 1rem;
  width: 100%;
}

hr {
  margin-top: 3px;
}

.can {
  border: none;
  background: rgb(220, 222, 222);
  height: 2.1rem;
  width: 6.9rem;
  float: right;
  font-size: medium;
  margin-right: 3rem;
}

.can:hover {
  background: rgb(197, 198, 198);
}

.call {
  width: 100%;
  height: 4.5rem;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  cursor: pointer;
}

.cal {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  padding-left: 4rem;
  padding-right: 3rem;
  cursor: pointer;
}
.ca {
  width: 100%;
  margin-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.st {
  color: rgb(3, 117, 239);
  cursor: pointer;
}

.call:hover {
  background-color: rgb(220, 222, 222);
  border-top: black 1px dashed;
  border-bottom: black 1px dashed;
}

@media screen and (max-width: 900px) {
  * {
    box-sizing: border-box;
    font-family: Haettenschweiler, "Arial Narrow Bold", sans-serif;
  }
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
  ::placeholder {
    color: rgb(143, 146, 146);
    font-size: medium;
  }
  .btn {
    border-radius: 4px;
  }
  .fg {
    color: rgb(24, 27, 206);
  }
  .back {
    display: none;
  }
  .body {
    height: 80vh;
    width: 100vw;
    top: 0%;
    left: 0%;
    box-shadow: none;
  }

  .cont {
    height: 80vh;
    width: 100vw;
    top: 0%;
    left: 0%;
    box-shadow: none;
    padding: 0px;
  }
  .form {
    height: 90%;
    width: 90%;
  }
  .footer {
    float: none;
  }
  .call {
    background-color: rgb(242, 243, 243);
    border-top: rgb(126, 123, 123) 1px dashed;
    border-bottom: rgb(126, 123, 123) 1px dashed;
  }
}
